$(document).ready(function() {
    $('.accordion_item').click(function(){
        $(this).find('.accordion_content').slideToggle();
        $(this).find('.accordion_item_arrow').toggleClass('rotate')
    })

    $('ul.header_nav_list a[href^="#"]').click(function (e) {
        var target = $(this).attr('href');
        $('html, body').animate({
            scrollTop: $(target).offset().top
        }, 500);
        return false;
    });

    $('.anchor_btn a[href^="#"]').click(function (e) {
        var catalog = $(this).attr('href');
        $('html, body').animate({
            scrollTop: $(catalog).offset().top
        }, 500);
        return false;
    });

    $('.header_btn a[href^="#"]').click(function (e) {
        var footer = $(this).attr('href');
        $('html, body').animate({
            scrollTop: $(footer).offset().top
        }, 500);
        return false;
    });

    function updateTextHeight() {
        var maxHeight = 0;
        $('.card_item_text').each(function() {
            var currentHeight = $(this).outerHeight();
            maxHeight = (currentHeight > maxHeight) ? currentHeight : maxHeight;
        });
        $('.card_item_text').css('height', maxHeight + 'px');
    }

    updateTextHeight();

    $(window).resize(function() {
        updateTextHeight();
    });

    $(".burger_menu_wrap").click(function(){
        $(".header_nav").slideToggle()
        $(".burger_menu_wrap").toggleClass("active");
        $(".burger_name").slideToggle()
    })



    function moveImgBlock() {
        var windowWidth = $(window).width();
        if (windowWidth < 768) {
            $('.about_company_img_wrap').insertAfter('.about_company_text:first');
        } else {
            $('.about_company_img_wrap').appendTo('.company_all_wrap');
        }
    }

    moveImgBlock();

    $(window).resize(function () {
        moveImgBlock();
    });


    // -----------
    function videoBtn() {
        var windowWidth = $(window).width();
        if (windowWidth < 768) {
            $('.video_block_btn').appendTo('.video_block_wrap');
        } else {
            $('.video_block_btn').appendTo('.video_block_text');
        }
    }

    videoBtn();

    $(window).resize(function () {
        videoBtn();
    });


    // -----------
    function productInfoTitle() {
        var windowWidth = $(window).width();
        if (windowWidth < 1200) {
            $('.product_info_title').prependTo('.product_image');
        } else {
            $('.product_info_title').prependTo('.product_info');
        }
    }

    productInfoTitle();

    $(window).resize(function () {
        productInfoTitle();
    });

    // -----------
    function anchorBtn() {
        var windowWidth = $(window).width();
        if (windowWidth < 1200) {
            $('.anchor_btn').appendTo('.company_text_wrap');
        } else {
            $('.anchor_btn').prependTo('.about_company_img_wrap');
        }
    }

    anchorBtn();

    $(window).resize(function () {
        anchorBtn();
    });


    $(window).scroll(function () {
        if ($(this).scrollTop() != 0)
            $('.main_arrow_top').fadeIn();
        else
            $('.main_arrow_top').fadeOut();
    });
    $('.main_arrow_top').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 800);
    });



    function productCardHeight() {
        var maxCardHeight = 0;
        $('.product_card_title').each(function() {
            var currentCardHeight = $(this).outerHeight();
            maxCardHeight = (currentCardHeight > maxCardHeight) ? currentCardHeight : maxCardHeight;
        });
        $('.product_card_title').css('height', maxCardHeight + 'px');
    }

    productCardHeight();

    $(window).resize(function() {
        productCardHeight();
    });

});

// require('./bootstrap');
require('./main');
require('./slick');
require('./lightgallery');

import lightGallery from 'lightgallery';
import lgZoom from 'lightgallery/plugins/zoom'

window.lightGallery = lightGallery;
window.lgZoom = lgZoom;

window.WOW = require('wowjs').WOW;

new window.WOW().init();

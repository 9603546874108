import $ from "jquery";
import "slick-carousel";
$(document).ready(function() {
    $('.video_list').slick({

        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        fade: true,
        speed: 1000,
        centerMode: false,
        prevArrow: $('.slider_arrow_left'),
        nextArrow: $('.slider_arrow_right'),
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ],
    });

    $('.slider_wrap').slick({

        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        centerMode: false,
        prevArrow: $('.aids_arrow_left'),
        nextArrow: $('.aids_arrow_right'),
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ],
    });




    $('.product_slider_for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        rows: 0,
        asNavFor: '.product_slider_nav'
    });

    $('.product_slider_nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        asNavFor: '.product_slider_for',
        dots: false,
        rows: 0,
        centerMode: false,
        focusOnSelect: true
    });
});
